import React from 'react';
import {Container, Row, Col, Card, CardBody, CardTitle, CardText} from "reactstrap";
import SectionTitleRow from "../shared/SectionTitleRow";
import Divider from "../shared/Divider";

import revivePackage from "../../static/images/revive-package.png";
import reviveAndThrivePackage from "../../static/images/revive-and-thrive-package.png";
import reviveAndThriveTogetherPackage from "../../static/images/revive-and-thrive-together-package.png";


const Fees = () => {
  return (
    <Container>
      <a className="ccc-anchor" id="fees" />
      <SectionTitleRow>Packages</SectionTitleRow>
      <Row>
        <Col xs={12} className="text-center">
          <p>
            I offer a choice of three coaching packages
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={4}>
          <Card>
            <div className="text-center">
              <img
                src={revivePackage}
                style={{maxWidth:'100%'}}
                alt="Revive package image"
              />
            </div>
            <CardBody>
              <CardTitle tag="h4" className="text-center">
                Revive
              </CardTitle>
              <CardText>
                <p>
                  This package is ideal for those keen to try a one-off coaching session but not yet ready to commit to the longer-term <em>Revive and Thrive</em> option.
                  It’s a great way to kickstart the process of reviving your life and seeing what’s possible.
                </p>
                <p>
                  It can also be used as a top-up or ad-hoc session for those who have already completed the <em>Revive and Thrive</em> package, or can be bought as a digital gift voucher for someone you know who wants to try coaching.
                  It includes:
                </p>
                <ul>
                  <li>Pre-coaching questionnaire</li>
                  <li>1 &times; 90-minute coaching session</li>
                  <li>Detailed session summary</li>
                  <li>Option to upgrade to the <em>Revive and Thrive</em> package</li>
                </ul>
              </CardText>
              <CardTitle tag="h5" className="text-center">
                £350
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card>
            <div className="text-center">
              <img
                src={reviveAndThrivePackage}
                style={{maxWidth:'100%'}}
                alt="Revive and Thrive Package image"
              />
            </div>
            <CardBody>
              <CardTitle tag="h4" className="text-center">
                Revive and Thrive
              </CardTitle>
              <CardText>
                <p>
                  This package is for those who want to thrive and are ready to commit to their personal and/or
                  professional growth over a 3-month period. It includes:
                </p>
                <ul>
                  <li>Pre-coaching questionnaire</li>
                  <li>5 &times; 90-minute coaching sessions (spaced out every 2-3 weeks for maximum impact)</li>
                  <li>Detailed session summaries</li>
                  <li>Support between sessions by WhatsApp/email to help navigate challenges</li>
                  <li>Relevant quizzes to deepen your self-awareness</li>
                  <li>Tools to overcome obstacles</li>
                  <li>Self-assessment worksheet to monitor your own progress between sessions</li>
                  <li>Special gift to celebrate the end of your coaching journey</li>
                </ul>
              </CardText>
              <CardTitle tag="h5" className="text-center">
                £1500
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card>
            <div className="text-center">
              <img
                src={reviveAndThriveTogetherPackage}
                style={{maxWidth:'100%'}}
                alt="Revive and Thrive Together package image"
              />
            </div>
            <CardBody>
              <CardTitle tag="h4" className="text-center">
                Revive and Thrive Together
              </CardTitle>
              <CardText>
                <p>
                  This is a bespoke group coaching programme that is designed to enhance the self-esteem, resilience and performance of a like-minded group of individuals.
                  Each person is supported in identifying and working towards their own goals, but within a diverse group of 4-6 people.
                </p>
                <p>
                  In addition to generating awareness and responsibility, participants gain from the insights and coaching of their peers and the strong sense of community that develops over time.
                </p>
                <p>
                  Group coaching offers a cost-effective alternative to one-to-one coaching.
                  If you’re interested in joining or creating a group, please contact me to learn more.
                </p>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="text-center mt-4">
            Coaching sessions take place via Zoom (or in person if local) at times convenient to you including evenings and weekends.
            You will leave each session with a challenging but empowering action plan which you are comfortable with and motivated by.
          </p>
        </Col>
      </Row>
      <Divider />
    </Container>
  )
}

export default Fees;